<template>
  <div class="bar-placeholder"></div>
  <div class="tab-bar">
    <div 
      class="bar-item" 
      :class="{'bar-item-active': active == index}" 
      v-for="(item,index) in list" 
      :key="index"
      @click="tolink(item.url)"
    >
      <img :src="active == index ? item.activeImg : item.img" />
      <div>{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

// import { ref } from 'vue';

export default {
  name: '',
  props: {
    active: {
      type: [Number, String],
      default: 0
    }
  },
  components: {},
  setup() {
    return {
      list: ref([])
    }
  },
  created() {
    this.reSet()
  },
  methods: {
    tolink(url) {
      this.$router.push(url)
    },
    reSet() {
      this.list = [
        {
          text: "首页",
          img: require("@/assets/img/tabbar/home.png"),
          activeImg: require("@/assets/img/tabbar/home-active.png"),
          url: "/"
        },
        {
          text: "行情",
          img: require("@/assets/img/tabbar/contract.png"),
          activeImg: require("@/assets/img/tabbar/contract-active.png"),
          url: "/Market"
        },
        {
          text: "投入",
          img: require("@/assets/img/tabbar/ai.png"),
          activeImg: require("@/assets/img/tabbar/ai-active.png"),
          url: "/Pools"
        },
        {
          text: "我的",
          img: require("@/assets/img/tabbar/user.png"),
          activeImg: require("@/assets/img/tabbar/user-active.png"),
          url: '/User'
        }
      ]
    }
  }
}
</script>

<style scoped>
  .tab-bar {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #010D0C;
    border-top: 1px solid #010D0C;
    z-index: 9;
  }

  .bar-item {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 10px;
  }

  .bar-item-active {
    color: #E7BA47;
  }

  .bar-item img {
    width: 24px;
  }

  .bar-placeholder {
    height: 50px;
  }
</style>