<template>
  <div>
    <Headbar bgColor="transparent" :isBack="false" title="行情"></Headbar>

    <div class="market-list">
      <div class="market-label">
        <div>交易对</div>
        <div class="text-right">最新价格</div>
      </div>
      <div class="market-val" v-for="item in marketList" :key="item.id">
        <div class="market-row">
          <div class="market-name">
            <img :src="require('@/assets/img/coin/' + item.symbol + '.png')" style="width: 25px;margin-right: 10px;">
            {{ item.symbol }} / USDT
          </div>
        </div>
        <div class="text-right">{{ item.priceOut.toFixed(6) }}</div>
      </div>
    </div>

    <TabBar active="1"></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { ref } from 'vue';
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {
    TabBar
  },
  created() {
    this.getEthContract()
  },
  setup() {
    return {
      trade: ref({ name: "Houbi", id: 1 }),
      tradeList: ref([]),
      marketList: ref([])
    }
  },
  methods: {
    getimg: function (img) {
      if (img != "") {
        return this.$config.send_url + "Upload/" + img;
      }
    },
    tabsChange(e) {
      this.trade = e
    },
    getEthContract() {
      let _this = this
      _this.$request.post(
        "api/EthContract/List",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
					let data = res.data.data
					let list = data.coins
					list.unshift({
						symbol: 'DG',
						priceOut: data.dg
					})
					_this.marketList = list
        }
      )
    },
  }
}
</script>

<style scoped>
.tabs {
  padding: 10px;
  display: flex;
  overflow-x: auto;
}

.market-list {
  padding: 0 10px;
}

.tabs-item {
  margin-right: 10px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 99px;
  border: 1px solid #FFD585;
  color: #fff;
}

.tabs-item:last-child {
  margin: 0;
}

.tabs-active {
  color: #000;
  background-color: #FFD585;
}

.market-label {
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  color: #fff;
  border-top: 1px solid #1A1A1A;
}

.market-label>div {
  flex: 1;
}

.trade-icon {
  margin-right: 4px;
  width: 15px;
  height: 15px;
}

.tag {
  width: 70px;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  background-color: #ff6059;
}

.tag-default {
  background-color: gray;
}

.tag-success {
  background-color: #2bbc6a;
}

.tag-error {
  background-color: #f56c6c;
}

.market-val {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.market-val>div {
  flex: 1;
  color: #fff;
}

.market-icon {
  margin-right: 6px;
  width: 28px;
  height: 28px;
}

.market-row {
  display: flex;
  align-items: center;
}

.text-right {
  text-align: right;
}

.market-name {
  display: flex;
  align-items: center;
}
</style>